import { useEffect, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import SiteBar from 'src/components/siteBar/siteBar';
import SiteFooter from 'src/components/footer/siteFooter';
import Alert from 'src/components/alert/alert';
import ModalRoot from 'src/components/modals/modalRoot';
import HeadSeo from 'src/utils/head';
import { clearCurrentCommunity } from 'src/modules/shared/context/actions';
import VerificationBar from 'src/components/verificationBar';
import BackToTop from 'src/components/buttons/backToTop';
import { Metadata } from 'src/constants/types';
import MaintenanceBanner from 'src/components/maintenanceBanner';
type Props = {
  children?: ReactNode;
  doClearCommunity?: boolean;
  isFullWidth?: boolean;
  seo?: Metadata;
};
export default function DefaultLayout({
  children,
  doClearCommunity = true,
  isFullWidth = false,
  seo
}: Props) {
  const dispatch = useDispatch();
  const communitySlug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  useEffect(() => {
    if (communitySlug && doClearCommunity) {
      dispatch(clearCurrentCommunity());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Container data-sentry-element="Container" data-sentry-component="DefaultLayout" data-sentry-source-file="default.tsx">
      <HeadSeo seo={seo} data-sentry-element="HeadSeo" data-sentry-source-file="default.tsx" />
      <SiteBar data-sentry-element="SiteBar" data-sentry-source-file="default.tsx" />
      <VerificationBar data-sentry-element="VerificationBar" data-sentry-source-file="default.tsx" />
      <MaintenanceBanner data-sentry-element="MaintenanceBanner" data-sentry-source-file="default.tsx" />
      <Alert data-sentry-element="Alert" data-sentry-source-file="default.tsx" />
      <Main $isFullWidth={isFullWidth} data-sentry-element="Main" data-sentry-source-file="default.tsx">
        {children}
      </Main>
      <BackToTop data-sentry-element="BackToTop" data-sentry-source-file="default.tsx" />
      <SiteFooter data-sentry-element="SiteFooter" data-sentry-source-file="default.tsx" />
      <ModalRoot data-sentry-element="ModalRoot" data-sentry-source-file="default.tsx" />
    </Container>;
}
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Main = styled.main<{
  $isFullWidth: boolean;
}>`
  margin-top: ${({
  theme
}) => theme.navHeightTop};
  flex: 1;
  ${({
  $isFullWidth
}) => $isFullWidth ? css`
    min-height: 300px;
  ` : css`
    padding: 15px;
    margin: ${({
  theme
}) => theme.navHeightTop} auto 0;
    max-width: 1200px;
    width: 100vw;

    @media (${({
  theme
}) => theme.underScreenSmall}) {
      padding: 0;
    }
  `}
  
  @media print {
    margin-top: 0;
  }
`;