import styled from 'styled-components';
import i18n from 'src/utils/translate';
export default function MaintenanceBanner() {
  return <Container data-sentry-element="Container" data-sentry-component="MaintenanceBanner" data-sentry-source-file="maintenanceBanner.tsx">
      <p>
        {i18n.t('HealthUnlocked will undergo site maintenance on 8th April 2025 from 11:30AM (BST) / 6:30AM (EDT) for a few hours. We aim to restore the full service as soon as possible.')}
        {' '}<a href="https://support.healthunlocked.com/article/183-site-maintenance" rel="noreferrer" target="_blank">{i18n.t('Learn more')}</a>
      </p>
    </Container>;
}
const Container = styled.div`
  position: relative;
  top: 50px;
  z-index: 1000;
  padding: 15px;
  background-color: ${({
  theme
}) => theme.colorYellowLight};
  text-align: center;
  color: ${({
  theme
}) => theme.colorWarning};
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 10px;
  }
  p {
    margin: auto;
    max-width: 750px;
  }
`;