// @ts-strict-ignore
import { useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import { Metadata } from 'src/constants/types';
import HeadSeo from 'src/utils/head';
import { fetchGuidelinesOnce } from 'src/modules/shared/context/actions';
import Alert from 'src/components/alert/alert';
import ModalRoot from 'src/components/modals/modalRoot';
import CommunityHeader from 'src/modules/community/shared/components/header/communityHeader';
import CommunityNavBar from 'src/modules/community/shared/components/header/communityHeaderNavBar';
import FloatingSignupBox from 'src/components/floatingSignupBox';
import SiteFooter from 'src/components/footer/siteFooter';
import Loader from 'src/components/loader';
import SiteBar from 'src/components/siteBar/siteBar';
import VerificationBar from 'src/components/verificationBar';
import BackToTop from 'src/components/buttons/backToTop';
import { ResultsContainer, ResultsContent, ResultsSidebar } from 'styles-js/resultsList';
import { H1 } from 'styles-js/mixins/screenReader';
import MaintenanceBanner from 'src/components/maintenanceBanner';
type CommunityLayout = {
  children?: ReactNode;
  currentPage?: string;
  fullWidth?: boolean;
  seo?: Metadata;
  sideBarContent?: ReactNode;
};
export default function CommunityLayout({
  children,
  currentPage,
  fullWidth,
  seo,
  sideBarContent
}: CommunityLayout) {
  const dispatch = useDispatch();
  const slug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  useEffect(() => {
    if (!slug) return;
    dispatch(fetchGuidelinesOnce(slug));
  }, [slug, dispatch]);
  return <Container data-sentry-element="Container" data-sentry-component="CommunityLayout" data-sentry-source-file="community.tsx">
      {slug && <HeadSeo seo={seo} />}
      <SiteBar data-sentry-element="SiteBar" data-sentry-source-file="community.tsx" />
      <VerificationBar data-sentry-element="VerificationBar" data-sentry-source-file="community.tsx" />
      <MaintenanceBanner data-sentry-element="MaintenanceBanner" data-sentry-source-file="community.tsx" />
      <Alert data-sentry-element="Alert" data-sentry-source-file="community.tsx" />
      {!slug ? <Loader /> : <Main>
            <H1>{seo?.title || 'HealthUnlocked | The social network for health'}</H1>
            <CommunityHeader currentPage={currentPage} />
            <CommunityNavBar currentPage={currentPage} />
            <ResultsContainer>
              {fullWidth ? <FullWidthContainer data-testid="community-results-content">
                    {children}
                  </FullWidthContainer> : <>
                    <ResultsContent data-testid="community-results-content">
                      {children}
                    </ResultsContent>
                    <ResultsSidebar>
                      {sideBarContent}
                    </ResultsSidebar>
                  </>}
            </ResultsContainer>
          </Main>}
      <FloatingSignupBox data-sentry-element="FloatingSignupBox" data-sentry-source-file="community.tsx" />
      <BackToTop data-sentry-element="BackToTop" data-sentry-source-file="community.tsx" />
      <SiteFooter data-sentry-element="SiteFooter" data-sentry-source-file="community.tsx" />
      <ModalRoot data-sentry-element="ModalRoot" data-sentry-source-file="community.tsx" />
    </Container>;
}
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Main = styled.main`
  margin-top: ${({
  theme
}) => theme.navHeightTop};
  flex: 1;
`;
const FullWidthContainer = styled.div`
  flex: 1;
  @media (${({
  theme
}) => theme.underScreenXlarge}) and (${({
  theme
}) => theme.overScreenSmall}) {
    margin-right: 20px;
  }
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 0 15px;
  }
`;